<template>
  <container>
    <div slot="left_box_content">
      <component :is="curLeftComponent"></component>
    </div>

    <div slot="right_box_content">
      <component :is="curRightComponent" ></component>
    </div>

    <div slot="bottom_box_content">
      <component :is="curBottomComponent" ></component>
    </div>

    <div slot="center_box_content">
      <component :is="curMapComponent" ></component>
    </div>
  </container>
</template>

<script>
import Container from '@/components/common/Container_DataV'
import { sysMixins } from '@/assets/mixins/sys'
// 引入store模块
import situationStoreModule from '@/store/modules/situation_web'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    Bottom: () => import('./components/Bottom'),
    Right: () => import('./components/Right'),
    Left: () => import('./components/Left'),
    Center: () => import('./components/Center')
  },
  created () {
    // 注册动态store模块
    if (!this.$store.hasModule('situationWeb')) {
      this.$store.registerModule('situationWeb', situationStoreModule)
    }
  },
  mounted () {
    // 如果登录公司ID不等于业主ID，表示为代理。 那么设置companyId为当前公司
    const loginCompanyId = this.$store.getters.token.userInfo.companyId
    const publisherId = this.$store.getters.token.userInfo.publisherId
    if (loginCompanyId !== publisherId) {
      this.$store.commit('set_situation_companyId', loginCompanyId)
    }

    this.setLeftComponent('Left')
    this.setRightComponent('Right')
    this.setBottomComponent('Bottom')
    this.setMapComponent('Center')

    this.setBottomHeight('300px')
  },
  destroyed () {
    // 卸载installSetting动态store模块
    this.$store.commit('reset_state')
    // this.$store.unregisterModule('situationWeb')
  }
}
</script>
